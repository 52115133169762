@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500&display=swap");
.A4 {
  margin: 0 auto;
  font-family: "Cairo", sans-serif;
  page-break-after: always;
  width: 297mm;
  height: 200mm;
  page-break-after: always;
  padding:10px 10px;
}
.content_earthlink {
  padding: 10px 20px;
}
.content_earthlink .info_header {
  margin-bottom: 20px;
}
.Main {
  padding: 20px 10px;
  margin: 0 auto;
  font-family: "Cairo", sans-serif;
  width: 13.8cm;
  max-height: 21.1cm;
  page-break-after: always;
}
.title_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.title {
  font-size: 20px;
  font-weight: 900;
  margin-right: 30px;
  color: #333;
}

.barcode_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.barcode_container div {
  font-weight: 800;
  color: #333;
  border-bottom: 2px solid #333;
}

.barCode {
  display: flex;
  justify-content: center;
}
.info_header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}
.info_header div {
  border: 1px solid #b30724;
  font-size: 13px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 3px 15px;
  width: fit-content;
  margin: 5px;
  font-weight: 700;
  word-spacing: 1px;
  letter-spacing: 0.8px;
  color: #333;
}
.date {
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid black;
}
.info_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}
.info_fieldE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}
.info_fieldE div {
  align-items: center;
  border: 1px solid #d1d1d1;
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  font-weight: 900;
  padding-left: 5px;
}
.info_field div {
  align-items: center;
  border: 1px solid #d1d1d1;
  width: 100%;
  font-size: 17px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  font-weight: 900;
  padding-left: 5px;
}
.arabic {
  letter-spacing: 0 !important;
  direction: rtl;
  padding: 0.8px;
}
.spinnerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  padding: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.upload_container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit_button {
  padding: 0 16px;
  border: none;
  font-weight: bold;
  height: 40px;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  background-color: #70b44d;
  color: white;
}
.submit_button:active {
  transform: translateY(1px) translateX(1px);
}
.download_button {
  color: #090c70;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid #090c70;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
}
.download_button:hover {
  opacity: 0.8;
}
.content_container {
  width: 600px;
  background: #ededed;
  height: 150px;
  border-radius: 20px;
  margin: 0 auto;
  padding: 30px 0;
}
.before {
  break-before: always;
}
.after {
  break-after: always;
}
.note {
  font-size: 14px;
  padding: 5px !important;
}
@media print {
  .break {
    page-break-after: always;
  }
}
.earthlink_container {
}
.Earthlink {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #090c70;
}
