.invouce {
  color: black;
  border: 1px solid red;
  padding: 10px 20px;
  width: 210mm;
  margin: 0 auto;
  .header_wrapper {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .logo{
    }
  }
  .table_container {
    margin-top: 20px;
  }
}
.invoice_date {
  p {
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    width: fit-content;
  }
}
.total_money {
  margin-top: 10px;
  p {
    font-size: 16px;
  }
}
.cus_name {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  P {
    font-size: 16px;
    color: rgb(235, 0, 40);
    border-bottom: 2px solid rgb(152, 149, 149);
    font-size: 20px;
    font-weight: 600;
  }
}

