.login-container {
    // border: 1px solid black;
    // padding: 32px;
    width: 500px;
    margin: 0 auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    align-items: center;
    height: 100vh;
    .logo {
      margin-top: 128px;
    }
  }
  .login-left-side {
    width: 100%;
    .login-headding {
      margin: 0;
      margin-bottom: 8px;
    }
    .login-text {
      margin: 0;
      margin-bottom: 24px;
    }
    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .input {
      width: 100%;
    }
    .button {
      margin-top: 32px;
      width: 30%;
    }
    .logo {
      @media only screen and (max-height: 520px) {
        visibility: hidden;
      }
    }
  }