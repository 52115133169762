.res_header {
  background-color: #ec163a;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0px 10px;
}
.res_container {
  margin-top: 14px;
  padding: 0px 15px;
  color: rgb(101, 101, 101);
}
.res_input {
  border: 1px solid #ddd;
  padding: 2px 5px;
  font-size: 16px;
  border-radius: 5px;
}

.Wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.TakePhotoButton {
  background: url("https://img.icons8.com/ios/50/000000/compact-camera.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.img_container {
  div{

    display: flex;
    margin-top: 20px;
  justify-content: start;
  align-items: flex-start;
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  margin-top: 10px;
  border: 1px solid red;
}
}
.res_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fixed {
  position: fixed;
  bottom: 0%;
  width: 100%;
  color: #505050;
  text-align: center;
}
.outline_button {
  background-color: white;
  color: #ff1d43;
  border: 1px solid #ff1d43 !important;
}
.camera_container {
  div{
    display: flex;
    margin-top: 20px;
    justify-content: center;
    // align-items: center;
    height: 50vh;
    width: 100%;
  // overflow-y: scroll;
  // flex-wrap: wrap;
  // margin-top: 10px;
  border: 1px solid red;
  }

}
.camera_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.absolute{
    position: absolute;
}