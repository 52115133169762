@tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;

      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;

      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;

      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;

      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;

      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;

      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;

      --radius: 0.5rem;
    }

    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;

      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;

      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;

      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 11.2%;

      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;

      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;

      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;

      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 212.7 26.8% 83.9%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }
  @import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  // font-family: "Roboto" !important;
  font-family: "Cairo", sans-serif !important;
}

#Invoice_print {
  display: none;
}

@media print {
  #Invoice_print {
    display: block;
  }
  .invouce {
    color: red !important;
  }
}
::-webkit-scrollbar {
  width: 1px;
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(210, 210, 210);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(147, 147, 147);
}
.defult_input {
  border: 1px solid #e9e9e9;
  background-color: #f5f5f5;
  outline: none;
  border-radius: 5px;
  padding: 0 5px;
  width: 100px;
  margin-left: 5px;
  height: 20px;
}
.empty_input {
  border: none;
  background-color: white;
  outline: none;
  border-radius: 5px;
  padding: 0 5px;
  border: none;
  width: 100%;
}
.spinnerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(85, 85, 85, 0.6);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.App {
  position: relative;
  height: 99vh;
  overflow: hidden;
  display: flex;
}
.main_contnet {
  height: 100vh;
  overflow-y: scroll;
  // color: red;
  flex: 1;
}
.button {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;

  padding: 0 12px;
  border: none;
  height: 30px;

  font-size: 12px;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
  &:hover {
    opacity: 0.5;
  }
}
.container {
  padding: 0px 30px;
}
.c70b44d {
  color: white;
  background-color: #70b44d;
  &:hover {
    background-color: #4d9429;
  }
}
.ff1d43 {
  color: white;
  background-color: #ff1d43;
  &:hover {
    background-color: #ee7387;
  }
}
.c8597A5 {
  color: white;
  background-color: #8597a5 !important;
  &:hover {
    background-color: #788085;
  }
}
.Header_wrapper {
  border-bottom: 1px solid rgb(228, 228, 228);
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #848484;
  align-items: center;
  font-size: 16px;
  padding: 5px 20px;
  box-shadow: rgba(240, 240, 240, 0.2) 0px 2px 8px 0px;
  .vendor_price {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .select_style {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #848484;
    border-radius: 5px;
  }
}
.Header_wrapper_vendor_accounting{
  border-bottom: 1px solid rgb(228, 228, 228);
  display: flex;
  width: 100%;
  justify-content:space-between;
  color: #848484;
  align-items: center;
  
  font-size: 16px;
  padding: 5px 20px;
  box-shadow: rgba(240, 240, 240, 0.2) 0px 2px 8px 0px;
  .vendor_price {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .select_style {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #848484;
    border-radius: 5px;
  }
}
.tasks_content {
  display: flex;
  margin: 30px auto;
  width: 90%;
  align-items: center;
  justify-content: space-between;

  .vendor_details {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex-wrap: wrap;
    div {
      display: flex;
      padding-left: 20px;
      font-size: 18px !important;
      color: #555;
      text-align: center;
      align-items: center;
      padding: 10px;
      span {
        color: #8597a5;
        margin-left: 5px;
      }
    }
  }
  .select_style {
    cursor: pointer;
    border: 1px solid rgb(169, 166, 166);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #848484;
    border-radius: 5px;
  }
}
.responsive-calendar {
  font-size: 10px !important;
}
.custome_datepicker {
  border: 1px solid rgb(169, 166, 166) !important;
  outline: none !important;
  font-size: 15px !important;
  width: 200px !important;
  height: 40px;
  font-weight: 600;

  border-radius: 5px;
  :focus {
    outline: none !important;
  }
}
.table_container {
  .thead {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #c4c4c4;
    margin-top: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    span {
      width: 100%;
      font-size: 12px;
      margin: 0px 2px;
    }
    :first-child {
      width: 30%;
      // color: red;
    }
  }
  .tbody {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    color: #505050;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 10px;
    text-align: left;
    cursor: pointer;

    span {
      width: 100%;
      font-size: 10px;
    }
    :first-child {
      width: 30%;
      // color: red;
    }
    .Number_Format {
      border: 1px solid #848484;
      border-radius: 4px;
      padding: 0 5px;
      font-size: 12px;
      background-color: #eeeeee;
      width: 60%;
    }
  }
  .tbody:hover {
    background-color: #ff5c77;
    color: white;
    .default_currency {
      color: white;
    }
    .edit_icon {
      color: white;
    }
    .delete_icon {
      color: white;
    }
  }
  .green {
    color: re;
  }
}
.loading_screen {
  direction: rtl;
  text-align: left;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 3vw;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: 1.4px;
  justify-content: center;
  text-align: center;
  .tamata_title {
    font-size: 30px;
    span {
      color: black;
    }
  }
}

.custom_input {
  border: 1px solid red;
}
.small_width {
  width: 60% !important;
}
.select_option {
  background-color: #8597a5;
  color: white;
  border: none;
  width: fit-content;
  height: 40px;
  font-size: 18px;
  border-radius: 8px;
}
.total_details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  margin: 15px 0;
  div {
    display: flex;
    p {
      border-bottom: 2px solid red;
    }
  }
}
// .Modal_header {
//   display: flex;
//   justify-content: space-between;
// }
.Exmple_download {
  color: blue;
  width: fit-content;
  border-bottom: 1px solid blue;
  cursor: pointer;
}
.default_currency {
  border: none;
  width: fit-content;
  width: 60% !important;
  color: black;
  font-size: 15px;
  background-color: transparent;
}
.defulut_iq_task {
  border: none;
  color: black;
  font-size: 15px;
  background-color: transparent;
}
.alert {
  color: #ff1d43;
}
.edit_icon {
  color: #707070;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100px !important;
  // border: 1px solid red;
  text-align: center;
}
.delete_icon {
  color: red;
}
// .flex {
//   display: flex;
//   align-items: center;
//   border: none;
// }
// start payments page
.payments_conatiner {
  width: 85%;
  margin: 20px auto;
}
.modal_container {
  width: 50vw;
}
.Modal_header {
  display: flex;
  justify-content: space-between;
}
.close_img {
  display: flex;
  justify-content: end;
}
.true {
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  color: white;
  margin: 10px 0;
  background: #70b44d;
}
.false {
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  margin: 10px 0;
  background: #fea120;

  color: #ffffff !important;
}
.delete {
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  margin: 10px 0;
  background: #ff0c35;

  color: #ffffff !important;
}
.pick_up {
  .p_header {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #848484;
    align-items: center;
    font-size: 16px;
    padding: 2px 20px;
    height: 50px;
    box-shadow: rgb(240 240 240 / 20%) 0px 2px 8px 0px;
  }
}
.search_container {
  margin-left: 20px;
}
.search_input {
  padding: 2px 5px;
  width: 120px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  outline: none;
  font-size: 12px;
}
.Export_button {
  width: 70px;
  border: none;
  font-size: 12px;
  border-radius: 8px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title {
  font-size: 20px;
  display: flex;
  color: #505050;
  span {
    margin-right: 20px;
  }
}
.input {
  padding: 0 16px;
  height: 40px;
  margin: 4px 0;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 8px;
  min-width: 240px;

  &:focus {
    border: 1px solid #eb0028;
  }
}
.ceb0028 {
  color: white;
  background-color: #eb0028;
  &:hover {
    background-color: #ca0022;
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #505050;
  text-align: center;
  padding: 15px 26px 8px 26px;

  .content{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    div{
      margin: 0px 8px;
    }
  }
}
.Modal_content {
  margin-top: 20px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.default_button {
  padding: 0px 8px;
  color: #70b44d;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: white;

  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
  span {
    font-size: 22px;
  }
}
.Denied_page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  font-size: 24px;
}
.entry_menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgb(228, 228, 228);
  color: #848484;
  font-size: 15px;
  padding: 5px 20px;
  box-shadow: rgba(240, 240, 240, 0.2) 0px 2px 8px 0px;
  div {
    // display: flex;
  }
  .delivery {
    background-color: #ff1d43;
    color: white;
    border-radius: 8px;
    padding: 0px 20px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fc7088;
    }
  }
}
.pending {
  cursor: pointer;
  color: #ff1d43;
  border: 1px solid #ff1d43 !important;
  border-radius: 8px;
  padding: 0px 10px;
  margin: 0 10px;
  background-color: #fff !important;
  &:hover {
    color: white !important;

    background-color: #ff1d43 !important;
  }
}
.Export_green {
  cursor: pointer;
  color: #70b44d;
  border: 1px solid #9ebf8e !important;
  border-radius: 8px;
  padding: 0px 10px;
  margin: 0 10px;
  background-color: #fff !important;
  &:hover {
    color: white !important;

    background-color: #4d9429 !important;
  }
}
.view_btn {
  cursor: pointer;
  color: #4d9429;
  font-size: 14px !important;
  font-weight: 900;
}
.work_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}
.work_space {
  display: flex;
  justify-content: center;
  // overflow: hidden !important;
  .work_container {
    width: 75vw !important;
    height: 100vh;
    overflow: scroll;
  }
  .empty_work {
    width: 100vw !important;
  }
  .slider {
    width: 25vw;
    height: 80vh;
  }
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  text-align: center;
  border: 1px solid red;
  width: 95vw;
  overflow-x: scroll;
}

#customers td {
  text-align: center;

  border: 1px solid #e6e6e6;
}
#customers thead th {
  border: 1px solid #e6e6e6;
  padding: 0;
  text-align: center;
}
#customers tbody td {
  border: 1px solid #4b4b4b;
  padding: 0;
  text-align: center;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: rgb(240, 240, 240);
}

#customers th {
  // padding: 8px;
  text-align: center;
  background-color: #ff1d43;
  color: white;
}
input {
  outline: none;
}
.green {
  // background: #e1f4d6;
  border-radius: 10px;
  line-height: 30px;
  color: #72b74d;
  text-align: center;
}
.started {
  background: #e1f4d6;
  border-radius: 10px;
  line-height: 30px;
  color: #72b74d;
  text-align: center;
}
.Pending {
  text-align: center;
  background: #e1f4d6;
  border-radius: 10px;
  width: fit-content;
  line-height: 30px;
  background: #f0d2d7;
  color: #eb0028;
}
.default_input {
  border: none;
  background: transparent;
}
.row_task {
  width: 120px;
  background: transparent;
  border: none;
}
.export {
  text-align: center;

  margin-left: 20px;
}

.export {
  cursor: pointer;
  color: white;
  border: 1px solid #70b44d !important;
  border-radius: 8px;
  padding: 0px 10px;
  margin-right: 10px;
  background-color: #70b44d;
  &:hover {
    color: #70b44d;
    background-color: white;
  }
}
.alert_cell {
  background-color: red !important;
}
.date_picker {
  font-size: 12px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  background-color: white;
}
input[type="date"] {
  font-size: 12px;
  border: 1px solid #8597a5;
  padding: 2px 8px;
  height: 28px;
  border-radius: 10px;
  background-color: #4e525c;
  color: #fff !important;
  margin-left: 10px;
}
::-webkit-datetime-edit-text {
  color: #fff;
}
::-webkit-datetime-edit-month-field {
  color: #fff;
}
::-webkit-datetime-edit-day-field {
  color: #fff;
}
::-webkit-datetime-edit-year-field {
  color: #fff;
}
::-webkit-calendar-picker-indicator {
  background-image: url(http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Calendar-icon.png);
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  color: rgba(204, 204, 204, 0);
}
.payment_modal {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 12px;
  }
}
.payment_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.return {
  color: white;
  background-color: #8597a5 !important;
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  margin: 10px 0;
  &:hover {
    background-color: #788085;
  }
}
.add_container {
  background-color: white;
  border: 1px solid #4d9429;
  border-radius: 20%;
  display: flex;
  height: fit-content;
  padding: 5px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: #eef7e9;
    color: white !important;
  }
}
.save_btn {
  margin-right: 20px;
  background-color: white;

  border: 1px solid #1c89ff;
  border-radius: 20%;
  display: flex;
  height: fit-content;
  padding: 5px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: #eef7e9;
    color: white !important;
  }
}
.selected_row {
  color: white !important;
  background-color: #ff5c77;
}

.tooltip_style {
  font-size: small;
  font-family: "Cairo";
}
.ReactModal__Overlay .ReactModal__Overlay {
  z-index: 9000;
}
.ReactModalPortal {
  z-index: 9000;
  position: relative;
}
.A4 {
  margin: 0 auto;
  font-family: "Cairo", sans-serif;
  page-break-after: always;
  width: 297mm;
  height: 200mm;
  page-break-after: always;
  padding: 10px 10px;
}
.content_earthlink {
  padding: 10px 20px;
}
.content_earthlink .info_header {
  margin-bottom: 20px;
}
.form-control {
  display: block;
}
.search_order {
  padding: 2px 5px;
  width: 400px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
}
.accounts_details {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
  div {
    background-color: rgba(238, 238, 238, 0.682) !important;
    width: 20%;
    height: 100px;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #505050;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.react-datepicker__close-icon {
  position: absolute;
  right: 0 !important;
  text-align: center;
  border-radius: 10px;
}
.react-datepicker__input-container {
}
.datepicker {
  font-family: "Exo 2", sans-serif;
}
.datepicker--cell.-range-to- {
  background: rgba(248, 206, 236, 0.4);
  border-color: rgba(248, 206, 236, 1);
}
.datepicker--cell.-current- {
}
.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-focus- {
  background-color: #f8ceec;
  color: #000000;
}
.datepicker--cell.-in-range- {
  background: rgba(248, 206, 236, 0.2);
}
.datepicker--cell-day {
  font-weight: 500;
  color: #000000;
}
.date_pick {
  border: 1px solid #bfbdbd;
  border-radius: 5px;
  min-height: 30px;
  text-align: center;
  font-size: 14px;
}
.react-datepicker__input-container {
}
.datepicker {
  font-family: "Exo 2", sans-serif;
}
.datepicker--cell.-range-to- {
  background: rgba(248, 206, 236, 0.4);
  border-color: rgba(248, 206, 236, 1);
}
.datepicker--cell.-current- {
}
.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-focus- {
  background-color: #f8ceec;
  color: #000000;
}
.datepicker--cell.-in-range- {
  background: rgba(248, 206, 236, 0.2);
}
.datepicker--cell-day {
  font-weight: 500;
  color: #000000;
}
.form-control {
  height: 30pxpx;
  font-size: 16px;
  color: #414d64;
  background: #ffffff;
  padding: 0 15px;
  border: 2px solid #dadeea;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
textarea.form-co6ntrol {
  height: 120px;
  padding-top: 15px;
}
.form-control-plaintext {
  font-size: 18px;
  color: #434343;
}
.card_title_price {
  background-color: #757575;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  width: fit-content;
  padding: 2px 20px;
  font-family: "Cairo";
  border-radius: 8px;
  margin: 8px 0;
}
.card_details_price {
  background-color: #ececec;
  border-radius: 8px;
  width: 300px;
  span {
    margin: 8px;
  }
  .currency {
    font-size: 22px;
    font-weight: 700;
  }
}
.gray_button {
  background-color: #4f5b67;
  color: white;
  border-radius: 3px;
  padding: 5px 16px;
  border: none;
  margin: 0px 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
}
.green_button {
  background-color: #6cc24a;
  color: white;
  border-radius: 3px;
  padding: 5px 16px;
  border: none;
  margin: 0px 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {    opacity: 0.6;
  }
}
.print_button {
  background: #ffac0f;
  color: white;
  border-radius: 3px;
  padding: 5px 16px;
  border: none;
  margin: 0px 4px;
  font-size: 16px;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  &:hover {
    opacity: 0.6;
  }
  div {
    margin: 0 4px;
  }
}
.vendorPortal {
  padding: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .order_count {
      border-radius: 8px;
      border: 1px solid rgba(79, 91, 103, 0.5);
      background: #4f5b67;
      padding: 3px 14px;
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .table_content {
    border: 1px solid rgba(79, 91, 103, 0.5);
    border-radius: 5px;
    height: 80vh;
    overflow-x: auto;
    .filters_components {
      padding: 15px 26px 8px 26px;
      border-bottom: 1px solid rgba(79, 91, 103, 0.2);
      width: 100%;
      display: flex;
      align-items: flex-end;
      div {
        margin: 0 2px;
      }
      .date_filter {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(79, 91, 103, 0.5);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        letter-spacing: 0.5px;
        padding: 10px;
        span {
          margin: 0px 2px;
          font-size: 16px;
          line-height: normal;
        }
        transition: all 0.4s ease-in-out;
        &:hover {
          opacity: 0.6;
        }
      }
      .text_filter {
        border-radius: 8px;
        border: 1px solid rgba(79, 91, 103, 0.5);
        width: 392px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 10px;
        span {
          margin: 0px 6px;
        }
        input {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
    .table_container {
      .table_header {
        padding: 18px 26px 8px 26px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid rgba(79, 91, 103, 0.2);
        padding: 15px 26px;

        span {
          width: 20%;
          color: #4f5b67;
          font-weight: 500;
        }
        .small_width{
          width: 5% !important;
        }
      }
      .table_body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 15px 26px;
        cursor: pointer;
        &:hover {
          background-color: #dfe1e2;
        }
        span {
          width: 20%;
          color: #4f5b67;
          font-weight: 400;
        }
        .small_width{
          width: 5% !important;
        }
        
      }
    }
    .order_status {
      border-radius: 16px;
      display: inline-flex;
      padding: 7px 17px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #ffefdb;
      color: #ff8a00;
    }
    .paid {
      color: #6cc24a;
      background: rgba(108, 194, 74, 0.15);
    }
    .return {
      color: #eb0028;
      background: rgba(235, 0, 40, 0.15);
    }
    .invoice {
      color: #4f5b67;
      background: rgba(79, 91, 103, 0.2);
    }
  }
}
.custom-file-input {
  position: relative;
}

.custom-button {
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  padding: 5px 16px;
  border: none;
  margin: 0px 4px;
  font-size: 16px;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  &:hover {
    opacity: 0.6;
  }
  div {
    margin: 0 4px;
  }
}

.custom-button:hover {
  background-color: #0056b3;
}
.table-container {
  width: 100%;
  margin: 0 auto;
  margin: 20px;
  color: rgb(185, 185, 185) !important;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
  color: #4f5b67;
}

.data-table th,
.data-table td {
  border: 1px solid rgba(79, 91, 103, 0.2);
  padding: 8px;
  text-align: left;
  // color: rgb(185, 185, 185) !important;
}

.data-table th {
  background-color: #f2f2f2; /* Header row background color */
  color: rgb(185, 185, 185) !important;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row background color */
  color: rgb(185, 185, 185) !important;
}

.data-table td:first-child {
  padding-left: 16px; /* Add spacing to the first column */
  color: rgb(185, 185, 185) !important;
}

.data-table tr:hover {
  background-color: #ddd; /* Hover effect */
}
.previous_pagination_button{
  background-color: #B1B9C0;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 22px;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  transition: 1s all ease-in-out;
cursor: pointer;
  span{
    margin: 0px 5px;
  }
  &:hover{
    opacity: 0.8;
  }
}
.next_pagination_button{
  background-color: #4F5B67;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 22px;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  transition: 0.7s all ease-in-out;
  cursor: pointer;

  span{
    margin: 0px 5px;
  }
  &:hover{
    opacity: 0.8;
  }
}
.disable_button{
  opacity: 0.4;
  cursor: not-allowed;
}
.paymentMonth{
  font-weight: 700;
  color: green;
  letter-spacing: 1px;
  font-size: 12px !important;
}