.Main {
    padding: 20px 10px;
    margin: 0 auto;
    font-family: "Cairo", sans-serif;
    width: 13.8cm;
    max-height: 21.1cm;
    page-break-after: always;
  }
  .title_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .title {
    font-size: 23px;
    font-weight: 900;
    margin-right: 30px;
    color: #333;
  }
  
  .barcode_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .barcode_container div {
    font-weight: 800;
    color: #333;
    border-bottom: 2px solid #333;
  }
  .barCode {
    display: flex;
    justify-content: center;
  }
  .info_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
  }
  .info_header div {
    border: 1px solid #b30724;
    font-size: 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 3px 15px;
    width: fit-content;
    margin: 5px;
    font-weight: 700;
    word-spacing: 1px;
    letter-spacing: 0.8px;
    color: #333;
  }
  .date {
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid black;
  }
  .info_field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
  }
  .info_fieldE {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
  }
  .info_fieldE div {
    align-items: center;
    border: 1px solid #d1d1d1;
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    word-spacing: 1px;
    letter-spacing: 0.5px;
    font-weight: 900;
    padding-left: 5px;
  }
  .info_field div {
    align-items: center;
    border: 1px solid #d1d1d1;
    width: 100%;
    font-size: 17px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    word-spacing: 1px;
    letter-spacing: 0.5px;
    font-weight: 900;
    padding-left: 5px;
  }
  .arabic {
    letter-spacing: 0 !important;
    direction: rtl;
    padding: 0.8px;
  }